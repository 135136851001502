<template>
    <div v-if="foundingMembers?.length > 0" class="founding-members">
        <h3 class="founding-members__title">Founding members include:</h3>
        <MemberTicker
            :items="foundingMembers"
        />
    </div>
</template>

<script setup>
const { data: foundingMembersResponse } = await useAsyncRestApi('users/founding-members'); 

const foundingMembers = computed(() => {
    return toValue(foundingMembersResponse).data;
});
</script>

<style lang="less" scoped>
.founding-members__title {
    .typography-h4();
    text-align: center;
    margin-bottom: var(--spacing-sm);
}
</style>

<template>
    <div
        ref="wrapper"
        class="member-ticker"
    >
        <div class="member-ticker__ticker">
            <div
                v-if="wrapperWasVisible"
                class="member-ticker__collection"
            >
                <MemberCard
                    v-for="(item, key) in [...items, ...items, ...items, ...items]"
                    :key="key"
                    :avatar="item?.avatar?.conversions?.webp_256 ?? null"
                    :name="item.full_name"
                    :function-title="item.job_title"
                    :institute="item.institute"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import { useElementVisibility } from '@vueuse/core';

const wrapper = ref(null);

const wrapperIsVisible = useElementVisibility(wrapper);

const wrapperWasVisible = ref(false);

watch(wrapperIsVisible, (value) => {
    if (value) {
        wrapperWasVisible.value = true;
    }
});

defineProps({
    items: {
        type: Array,
        required: true,
        default: null
    }
});
</script>

<style lang="less" src="./MemberTicker.less" />
